import api from '../../../api/api'
import { _orderByName } from '../../../utils/formatter'

export default {
  list: async ({ params, farms }) => {
    const data = await api.patch.filters(params, farms)

    const fazendas = _orderByName({
      data: data?.fazendas.map(farm => {
        return {
          ...farm,
          name: farm.pessoajuridica.NomeFantasia,
          id: farm.CodFazenda,
        }
      }),
      key: 'name',
    })

    const consultores = _orderByName({
      data: data?.consultores.map(consultor => {
        return {
          ...consultor,
        }
      }),
      key: 'name',
    })

    let produtores = _orderByName({
      data: data?.produtores.map(producer => {
        return {
          ...producer,
        }
      }),
      key: 'name',
    })

    produtores = produtores.filter(producer => producer.name !== '')

    let agroindustrias = _orderByName({
      data: data?.agroindustrias.map(agroindustria => {
        return {
          ...agroindustria,
        }
      }),
      key: 'name',
    })

    agroindustrias = agroindustrias.filter(
      agroindustria => agroindustria.name !== '',
    )

    return {
      fazendas,
      consultores,
      produtores,
      agroindustrias,
    }
  },
}
